import React from 'react';

const HomeCapabilities = () => {
   return (
     <React.Fragment>
         <section className='job_area clearfix'>
            <div className='container-fluid p-0 clearfix'>
               <div className='row g-0 align-items-center jutify-content-center clearfix'>
                <div className='col-sm-12 col-md-12 col-lg-6'>
                  <div className='job_left'>
                      <div className='left_img'>
                       <img src='assets/img/left-img.webp' />
                      </div>
                      <div className='left_content'>
                      <h3 className='job_title'>For Job Seekers</h3>
                       <h5 className='job_subtitle'>Find your next opportunity with us.</h5> 
                       <p className='job_desc'>By representing you and your interests, our Talent Acquisition consultants will ensure to properly set expectations and next steps and work to get you in front of the best companies with the best opportunities in the market today. Whether it be on-site work or something fully remote, the search for your ideal job begins when you become part of our growing network of professionals. Click here to join our network.</p>
                       <div className='job_btn'>
                        <a href='tel:+91-9717834009' className='btntstyle'>Schedule A Call Today 
                        
                        </a>
                       </div>
                      </div>
                  </div>                
                </div>
                <div className='col-sm-12 col-md-12 col-lg-6'>
                  <div className='job_left'>
                      <div className='left_img'>
                       <img src='assets/img/right-img.webp'/>
                      </div>
                      <div className='left_content'>
                      <h3 className='job_title'>For Employers</h3>
                       <h5 className='job_subtitle'>Connect with the right Talent</h5> 
                       <p className='job_desc'>Our business development managers can provide you with the decisional advantage when it comes to your organization’s staffing needs. Acting as your business partner, we make sure that we are able to update and immediately present to you what is available in the talent market today.</p>
                       <div className='job_btn' id="services">
                        <a href='tel:+91-9717834009' className='btntstyle'>Schedule A Call Today</a>
                       </div>
                      </div>
                  </div>                
                </div>
                {/* <div className='col-sm-12 col-md-12 col-lg-6'>
                  <div className='job_right'>
                      <h3 className='job_title'>The Resilience Advantage</h3>
                       <h5 className='job_subtitle'>We're here to inform which tactics need funding and which are drainsom</h5> 
                      <p className='job_desc'>By representing you and your interests, our Talent Acquisition consultants will ensure to properly set expectations and next steps and work to get you in front of the best companies with the best opportunities in the market today. Whether it be on-site work or something fully remote, the search for your ideal job begins when you become part of our growing network of professionals. Click here to join our network.</p>
                  </div>
                </div> */}
               </div>
            </div>
         </section>
     </React.Fragment>
   );
};

export default HomeCapabilities;