import React from 'react';
const items = [
   {
     heading: "Financial Services",
     imgSrc: "assets/img/icons/financial-services.png"
   },
   {
      heading: "Life Sciences",    
      imgSrc: "assets/img/icons/science.png"
    },
    {
      heading: " Information Technology",    
      imgSrc: "assets/img/icons/infrastructure.png"
    },
    {
      heading: " Insurance",    
      imgSrc: "assets/img/icons/investment.png"
    },
    {
      heading: "BPO & Shared Services",    
      imgSrc: "assets/img/icons/customer-service.png"
    },
    {
      heading: "Engineering & Construction",    
      imgSrc: "assets/img/icons/support.png"
    },
    {
      heading: "Retail",    
      imgSrc: "assets/img/icons/retailer.png"
    },
    {
      heading: "Media & Entertainment",    
      imgSrc: "assets/img/icons/internet.png"
    },
     
   // ... add more items as needed
 ];
 const Card = ({ heading, text, imgSrc }) => (
   <div className="services_box text-center">
      <div className='icons_box'><img src={imgSrc} alt={heading} /></div>
      <div className='services_content_box'> <h3>{heading}</h3>
     
   </div>
   </div>
 );
function HomeServices() {
	return (
      <React.Fragment>
		            <section className='services__area' >
               <div className='container clearfix'>
                  <div className='row'>
                     <div className='col-lg-12 col-sm-12 col-md-12'>
                        <div className='serivces_title text-center'>
                           <h2 className='underline'> Industries Served</h2>
                        </div>
                     </div>
                     {items.map((item, index) => (
                     <div className='col-lg-3 col-sm-12 col-md-3 col-6'>                   
                         <Card key={index} heading={item.heading}  imgSrc={item.imgSrc}/>                        
                     </div>
                     ))}
                  </div>
               </div>
            </section>         
         </React.Fragment>
	);
}

export default HomeServices;
