import React from 'react';
// import { Link } from 'react-router-dom';

const CommonCtaArea = () => {
   return (
      <React.Fragment>
            <section className='common__cta_section clearfix'>
                  <div className='container clearfix'>
                        <div className='row align-items-center clearfix'>
                           <div className='col-sm-12 col-md-12 col-lg-9'>
                              <div className='main_title_cta'>
                                 <h2>No Contracts. No Minimums. No Kidding</h2>
                                 <p>Embrace cost-effeciency, risk management, and enchanced valye through our adaptable pricing. Share your requirements with us.  Intiate a call today, and let's get started</p>
                              </div>
                           </div>
                           <div className='col-sm-12 col-md-12 col-lg-3'>
                                 <div className='main_btn_cta float-lg-end'>
                                    <a href="tel:(+91) 971 783 4009" className='btntstyle'>Schedule A Call Today</a>
                                 </div>
                           </div>
                        </div>
                  </div>
            </section>
      </React.Fragment>
   );
};

export default CommonCtaArea;