import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollTop from "./components/ScrollTop";
import Home from './pages/Home/Home';
import About from "./pages/About/About";
import Services from "./pages/Services/Services";
import Contact from "./pages/Contact/Contact";
// import BlogDetails from "./pages/BlogDetails/BlogDetails";
// import Blogs from "./pages/Blogs/Blogs";
// import Portfolio from "./pages/Portfolio/Portfolio";
// import PortfolioDetails from "./pages/PortfolioDetails/PortfolioDetails";
// import ServicesDetails from "./pages/ServicesDetails/ServicesDetails";
// import Team from "./pages/Team/Team";
// import TeamDetails from "./pages/TeamDetails/TeamDetails";


function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <ScrollTop/>
        <Routes>
            <Route path="/" element={<Home/>} />                  
            <Route path="/about" element={<About/>} />
            <Route path="/services" element={<Services/>} />
            {/* <Route path="/servicesDetails" element={<ServicesDetails/>} />
            <Route path="/portfolio" element={<Portfolio/>} />
            <Route path="/portfolioDetails" element={<PortfolioDetails/>} />
            <Route path="/team" element={<Team/>} />
            <Route path="/teamDetails" element={<TeamDetails/>} />
            <Route path="/blogs" element={<Blogs/>} />
            <Route path="/blogDetails" element={<BlogDetails/>} /> */}
            <Route path="/contact" element={<Contact/>} />
        </Routes>
      </BrowserRouter>
      </React.Fragment>
  );
}

export default App;
