import React from "react";
import Slider from "react-slick";

function ImageSlider(props) {
  const settings = {
    dots: false,
    infinite: true,     
    slidesToShow: 6,
    slidesToScroll: 1,
    speed: 5000,
    autoplay: true,
    autoplaySpeed: 0,
    centerMode: true,
    cssEase: 'linear',    
    initialSlide: 1,
    arrows: false,
    focusOnSelect: true,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <Slider {...settings}>
      {props.images.map((image, index) => (
        <div key={index}>
          <img src={image} alt={`slide-${index}`} />
        </div>
      ))}
    </Slider>
  );
}

function HomeBrands() {
  const images = [
    'assets/img/partner/1.png',
    'assets/img/partner/2.png',
    'assets/img/partner/3.png',
    'assets/img/partner/4.png',
    'assets/img/partner/5.png',
    'assets/img/partner/6.png',
    'assets/img/partner/7.png',
    'assets/img/partner/8.png'
  ];

  return (
    <React.Fragment>
      <section className="brands_section">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="partner_logo" id="contact">
                <ImageSlider images={images} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default HomeBrands;
