import React from 'react';
import { FaEnvelope, FaFacebookF, FaPhoneAlt, FaTwitter, FaVimeoV } from 'react-icons/fa';
import { BiMap } from 'react-icons/bi';
import Backtotop from '../../components/shared/Backtotop';
import { Link } from 'react-router-dom';

const Footer = () => {
   return (
      <>
         <footer>
            <div className="footer__area footer_bg  pt-100">
               <div className="footer__top pb-45">
                  <div className="container">
                     <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                           <div className="footer__widget mb-45">
                              <div className="footer__logo">
                              <a href="/"><img src="assets/img/logo/footer-logo.png" alt="logo"/></a>
                              <p>At DriveIT- Recruitment, we believe that recruitment is not just about filling positions but building relationships. </p>
                              </div>
                              
                           </div>
                        </div>
                        <div className="col-xl-3 offset-xl-1 col-lg-3 offset-lg-1 col-xl-3 col-lg-3 col-md-3 col-sm-6">
                           <div className="footer__widget mb-45">
                              <div className="footer__widget-title">
                                 <h4>Contact Us</h4>
                              </div>
                              <div className="footer__widget-content">
                                 <div className="footer__info">
                                    <ul>
                                       <li>
                                          <div className="icon">
                                             <i > <BiMap /> </i>
                                          </div>
                                          <div className="text">
                                             <span>D-215, Sector 63, Noida-201301, Uttar Pradesh, India</span>
                                          </div>
                                       </li>
                                       <li>
                                          <div className="icon ">
                                             <i > <FaEnvelope/> </i>
                                          </div>
                                          <div className="text ">
                                             <span><a href="mailto:hr@driveitrecruitment.com">hr@driveitrecruitment.com</a></span>
                                          </div>
                                       </li>
                                       <li>
                                          <div className="icon">
                                             <i ><FaPhoneAlt/> </i>
                                          </div>
                                          <div className="text">
                                             <span><a href="tel: (+91) 971 783 4009"> (+91) 971 783 4009</a></span>
                                          </div>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        
                        <div className="col-xl-3 offset-xl-1 col-lg-3 offset-lg-1 col-xl-3 col-lg-3 col-md-3 col-sm-6">
                        <div class="footer__widget mb-45"><div class="widget_subscribe_box">
                           <div class="subscribe_title">
                              <h2 class="footer-widget-title">Subscribe</h2>
                              <p>Subscribe our newsletter to get the latest news</p></div>
                              <div class="form-floating mb-4">
                              <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com"/>
                                 <label for="floatingInput">Email address</label></div><div class="subscribe_btn">
                                    <input type="submit" class="btn d-block w-100" value="Subscribe"/></div>
                                    </div>
                                    </div>
                        </div>
                     
                     </div>
                  </div>
               </div>
               <div className="footer__copyright">
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-xl-12 col-lg-12 col-md-12">
                           <div className="footer__copyright-text text-center">
                              <p>Copyright © {(new Date().getFullYear())} All Rights Reserved passion by <Link to="/">DriveIT Recruitment</Link></p>
                           </div>
                        </div>
  
                     </div>
                  </div>
               </div>
            </div>
         </footer>
         <Backtotop/>
      </>
   );
};

export default Footer;