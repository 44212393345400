import { Link } from 'react-router-dom';
import React from 'react';
const HomeAbout = () => {
   return (
      <React.Fragment>
      
      
         <section className="about__area pb-100 pt-100 pt-sm-30" id="about_us">
            <div className="container  clearfix">
                  <div className="row g-3 align-items-center">
                  <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                  <div className="sketch-one">
                            <img src="assets/img/image-card-sketch.png" alt="shape"/>
                        </div>
                     <div className="about__thumb">
                        <img src="assets/img/about.jpg" alt="" />
                     </div>
                  </div>
                     <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                        <div className="heading_job p-40">
                           <h5 className="about__title">About DriveIT Recruitment</h5>
                           <h2>Get To Know Our <span className="bottom_line">Experienced </span> <br/>HR  Consulting Team</h2>
                        </div>
                        <div class="content__text_job p-40"><p>At DriveIT- Recruitment, we believe that recruitment is not just about filling positions but building relationships. We take the time to understand the unique needs and goals of our clients and candidates and work tirelessly to ensure a successful match.</p><p>Our team of experienced HR professionals uses a personalized approach to organizational HR needs. We take deeper dive into each candidate's experience, skills, and personality and provide you with valuable insight and sage advice on the market trends of your sector. We use the latest tools and strategies to streamline our recruitment process and provide the best possible experience for our clients and candidates.</p><p>Whether you are a client looking for HR solutions or candidates seeking your next career move, DriveiT- Recruitment is here to help.</p></div>
                        <div className="about__btn p-40">
                           <a href="#contacts" className="btn_comman">Get In Touch</a>
                        </div>
                     </div>
                  </div>
               </div>
         </section>
         </React.Fragment>
   );
};

export default HomeAbout;