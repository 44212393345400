import React from 'react';
import { Helmet } from "react-helmet";

const PageHelmet = ({ pageTitle }) => {
    return (
        <>
            <Helmet>
                
                <meta charSet="utf-8" />
                <title>DriveIT Recruitment </title>  
                <meta name="google-site-verification" content="2YHKmHodCvG3Der-N67uRbSTh0CPTDs3rNediD2XA8g" />   
                <meta name="google-site-verification" content="Xm30atQGMH0hpwvkIfL2y3v9XWsPLl3tfDmR9fWvEqg" />     
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no"/>
                <meta name="theme-color" content="#061237" />
                <meta name="keywords" content="DriveIT- Recruitment" />
                <meta name="description" content="At DriveIT- Recruitment, we believe that recruitment is not just about filling positions but building relationships." />

            </Helmet>
        </>
    );
};

export default PageHelmet;