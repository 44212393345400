import React from 'react';

const HomeContact = () => {
   return (
     <React.Fragment>
         <section className='contact_section' id="contacts">
            <div className='container clearfix'>
               <div className='row  clearfix'>
                  <div className='col-md-12 col-sm-12 col-lg-12'>
                     <div className='contact_box row'>
                           <div className='col-md-5 ccol-sm-12 col-lg-5'>
                            <div className='contact_details'>
                                  <div className='contact_title mb-lg-5 mb-sm-2'>
                                    <h4>Contact Information</h4>
                                    <p>Fill Up the form and our Team will get back to you within 24 hours</p>
                                  </div>
                                  <div className='contact_details_map'>
                                       <ul>
                                          <li><a href='tel: (+91) 971 783 4009'><i className="fa fa-phone-alt icon_add" aria-hidden="true"></i>  (+91) 971 783 4009</a></li>
                                          <li><a href='mailto:hr@driveitrecruitment.com'><i className="fa fa-envelope  icon_add" aria-hidden="true"></i> hr@driveitrecruitment.com </a></li>
                                          <li><a href='https://goo.gl/maps/bjiWXqLQXoMBuBDaA' target='_blank'><i className="fa fa-map-marker icon_add" aria-hidden="true"></i>  D-215, Sector 63, Noida-201301, Uttar Pradesh, India</a></li>
                                       </ul>
                                       <div className='map'>
                                          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.051952980776!2d77.38006631454006!3d28.628204982419525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1946b5610ebb%3A0xcc20afb3ce1abbb!2sDriveIT+Digital!5e0!3m2!1sen!2sin!4v1555924837960!5m2!1sen!2sin" >

                                          </iframe>
                                       </div>
                                  </div>
                            </div>
                            </div>

                            <div className='col-md-7 col-sm-12 col-lg-7'>
                                 <div className='form_contact'>
                                 <form className='row'>
                                    <div className='col-md-12 col-sm-12 col-lg-12'>
                                          <div className='contact_form_title'>
                                             <h4>Send Message</h4>
                                           <h2>Drop Us A Line</h2>
                                          </div>
                                    </div>
                                 <div className='col-md-6 col-sm-6 col-lg-6'>
                                    <div className="form-floating mb-4">
                                          <input type="text" className="form-control" id="floatingInput" placeholder="Full Name"/>
                                          <label for="floatingInput">Full Name</label>
                                    </div>
                                    </div>
                                   
                                    <div className='col-md-6 col-sm-6 col-lg-6'>
                                    <div className="form-floating mb-4">
                                          <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com"/>
                                          <label for="floatingInput">Email address</label>
                                    </div>
                                    </div>
                                    <div className='col-md-6 col-sm-6 col-lg-6'>
                                    <div className="form-floating mb-4">
                                          <input type="number" className="form-control" id="floatingInput" placeholder="Phone Number"/>
                                          <label for="floatingInput">Phone Number</label>
                                    </div>
                                    </div>
                                    <div className='col-md-6 col-sm-6 col-lg-6'>
                                    <div className="form-floating mb-4">
                                    <select class="form-select" id="floatingSelect" aria-label="Floating label select example">
                                          <option selected>Open this select menu</option>
                                          <option value="Financial Services">Financial Services</option>
                                          <option value="Life Sciences">Life Sciences</option>
                                          <option value="Information Technology">Information Technology</option>
                                          <option value="Insurance">Insurance</option>
                                          <option value="BPO & Shared Services">BPO & Shared Services</option>
                                          <option value="Engineering & Construction">Engineering & Construction</option>
                                          <option value="Retail">Retail</option>
                                          <option value="Media & Entertainment">Media & Entertainment</option>
                                       </select>
                                       <label for="floatingSelect">Select Services</label>
                                    </div>
                                    </div>
                                    <div className='col-md-12 col-sm-12 col-lg-12'>
                                    <div class="form-floating mb-4">
                                    <textarea class="form-control" placeholder="Please Type Your Message....." id="floatingTextarea"></textarea>
                                    <label for="floatingTextarea">Please Type Your Message.....</label>
                                    </div>
                                    </div>
                                    <div className='col-md-12 col-sm-12 col-lg-12'>
                                       <input type="submit" className="submit_btn"/>
                                    </div>
                                 </form>
                                 </div>
                            </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
     </React.Fragment>
   );
};

export default HomeContact;