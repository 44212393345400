import { useState } from 'react';
import { FaFacebookF, FaTwitter, FaPhoneAlt, FaEnvelope, FaSearch, FaLinkedinIn, FaPhoneVolume } from 'react-icons/fa';

import { Link, NavLink } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar/Sidebar';
import useGlobalContext from '../../../hooks/useGlobalContext';

const HomeOneHeader = () => {
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const { stickyMenu } = useGlobalContext();
   return (
      <>
         <header>
            <div className="header__area p-relative header__transparent">
               <div className="header__top d-none d-md-block">
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-5 col-md-4">
                           <div className="header__social">
                              <ul>
                                 <li><a href="#"><i ><FaFacebookF/></i> </a></li>
                                 <li><a href="#"><i ><FaTwitter/> </i></a> </li>
                                 <li><a href="#"><i ><FaLinkedinIn/></i></a></li>
                              </ul>
                           </div>
                        </div>
                        <div className="col-xl-6 col-lg-7 col-md-8">
                           <div className="header__info f-right">
                              <ul>
                                
                                 <li>
                                    <a href="mailto:hr@driveitrecruitment.com ">
                                       <i > <FaEnvelope/> </i>
                                       hr@driveitrecruitment.com 
                                    </a>
                                 </li>
                                
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div id="header__sticky" className={stickyMenu ? "sticky header__bottom" : "header__bottom"}>
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                           <div className="logo mt-2">
                              <NavLink to="/">
                                     <img src="assets/img/logo/header-logo.png" alt="logo"/>
                              </NavLink>
                              
                           </div>
                           <div className="logo-gradient mt-2">
                              <NavLink to="/">
                              <img src="assets/img/logo/header-logo.png" alt="logo"/>
                              </NavLink>
                              
                           </div>
                        </div>
                        <div className="col-xl-9 col-lg-9 col-md-6 col-sm-6 col-6">
                           <div className="header__bottom-right d-flex justify-content-end align-items-center">
                             <div className="main-menu menu_wrapper_one">
                                 <nav id="mobile-menu">
                                    <ul>
                                       {/* <li>
                                          <NavLink  to="/">Home</NavLink>                                        
                                       </li> */}
                                       <li><a href='#home'>Home </a></li>
                                       {/* <li><NavLink to="/about">About Us </NavLink></li> */}
                                       <li><a href='#about_us'>About Us </a></li>
                                       {/* <li>
                                          <NavLink to="/services">Services</NavLink>                                         
                                       </li> */}
                                       <li><a href='#services'>Industries Served</a></li>
                                       {/* <li>
                                          <NavLink to="/portfolio">Careers/Jobs</NavLink>                                         
                                       </li> */}
                                       {/* <li>
                                          <NavLink to="/blogs">Blog</NavLink>                                          
                                       </li> */}
                                          {/* <li><a href='javascript:void(0);'>Careers/Jobs </a></li> */}
                                          <li><a href='#contacts'>Contact Us </a></li>
                                       {/* <li><a href='javascript:void(0);'>Blog </a></li> */}
                                       {/* <li>
                                          <NavLink to="/contact">Contact Us</NavLink>                                          
                                       </li> */}
                                       
                                    </ul>
                                 </nav>
                              </div>
                              <div className="header__btn d-none d-sm-block d-lg-none d-xl-block ml-50">
                                 <a href='tel:+91-9717834009' className="call_btn"><FaPhoneVolume/>  (+91) 971 783 4009</a>
                              </div>
                              <div onClick={handleShow} className="sidebar__menu d-lg-none">
                                 <div className="sidebar-toggle-btn" id="sidebar-toggle">
                                    <span className="line"></span>
                                    <span className="line"></span>
                                    <span className="line"></span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
              
               <div className="body-overlay-2"></div>
            </div>
         </header>



         <Sidebar show={show} handleClose={handleClose} />

      </>
   );
};

export default HomeOneHeader;